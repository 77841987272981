import { Route, Routes } from 'react-router-dom';
import routes from './config';

const ConfigRoutes = () => {
  return (
    <Routes>
      {routes.map((list) => {
        const { element: Layout, children, basePath } = list;
        return (
          <Route path={basePath} element={<Layout />} key={basePath}>
            {children.map((el) => {
              const { path, element: Component } = el;
              return <Route path={path} element={<Component />} key={path} />;
            })}
          </Route>
        );
      })}
    </Routes>
  );
};

export default ConfigRoutes;
