import { useTheme } from '@mui/material';
import { createContext, ReactNode, useContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { ContextProps } from 'interfaces';

export const German = createContext({} as ContextProps);

export const useGermanContext = () => useContext(German);

const GermanProvider = ({ children }: { children: ReactNode }) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const { pathname } = useLocation();

  useEffect(() => {
    goToTop();
    // eslint-disable-next-line
  }, [pathname]);

  const goToTop = () => window.scroll({ top: 0, left: 0, behavior: 'smooth' });

  const values: ContextProps = useMemo(() => ({ isDark }), [isDark]);

  return <German.Provider value={values}>{children}</German.Provider>;
};
export default GermanProvider;
