import ConfigRoutes from "routes";
import ThemeConfig from "theme";
import GlobalStyles from "theme/GlobalStyles";
import { BrowserRouter } from "react-router-dom";
import GermanProvider from "context";

const App = () => {
  return (
    <ThemeConfig mode="dark">
      <GlobalStyles />
      <BrowserRouter>
        <GermanProvider>
          <ConfigRoutes />
        </GermanProvider>
      </BrowserRouter>
    </ThemeConfig>
  );
};

export default App;
