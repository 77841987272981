import { lazy } from 'react';
import Loadable from './Loadable';
// ----------------------------------------------------------------------
const Page404 = Loadable(lazy(() => import('pages/Errors/Page404')));
// ----------------------------------------------------------------------
const Layout = Loadable(lazy(() => import('layout')));
// ----------------------------------------------------------------------
const Home = Loadable(lazy(() => import('pages/Home')));
const Projects = Loadable(lazy(() => import('pages/Projects')));

const routes = [
  {
    basePath: '/',
    element: Layout,
    children: [
      { path: '', element: Home },
      { path: 'projects', element: Projects },
      { path: '*', element: Page404 },
    ],
  },
];

export default routes;
